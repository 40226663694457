'use client'

import { signInWithKeycloack } from '@utils/authHelpers'
import { useSearchParams } from 'next/navigation'
import parentLogger from '@utils/logger'

const logger = parentLogger.child({ name: 'LoginPage' })

export default function Login() {
  const query = useSearchParams()
  const redirectRouteParameter = query.get('redirect_route') || '/'
  const skipProfile = query.get('skip_profile') === 'true'

  logger.info({ redirectRouteParameter, skipProfile }, 'Login with params')

  signInWithKeycloack({
    redirectRouteParameter,
    skipProfile,
  })

  return null
}
